import React from 'react'
import MainText from '../../text/MainText'
import { Package, ALevelProgram } from '../../../utils/Interfaces'

interface PaymentSectionProps {
  selectedPackage: Package | ALevelProgram | null;
}
const PaymentSection: React.FC<PaymentSectionProps> = ({ selectedPackage  }) => {

  return (
    <div>
      <MainText text={"Your Payment"} />
      <div className='w-full mt-2 h-[2px] rounded-full bg-black' />

      <div className='py-4 flex justify-between'>
        <MainText text={"Registration Fee"} />
        <div className='text-right'>
          {
            selectedPackage && (
              <MainText text={`£200`} />
            )
          }
        </div>
      </div>
     
    
    </div>
  )
}

export default PaymentSection