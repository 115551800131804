export const Programms = [
  {
    "name": "Academic Excellence",
    "age": "Academic Excellence",
    "discount": 0,
    "min_select": 1,
    "selected": 1,
    "condition": {
      "selected": 5,
      "discount": 0
    },
    "package": [
      {
        'id': 1,
        'name': '12th-19th July',
        'price': 0,
        'exist': true
      },
      {
        'id': 2,
        'name': '19th-26th July',
        'price': 0,
        'exist': false
      },
      {
        'id': 3,
        'name': '2nd-9th August',
        'price': 0,
        'exist': false
      },
      {
        'id': 4,
        'name': '9th-16th August',
        'price': 0,
        'exist': false
      },
      {
        'id': 5,
        'name': '16th-23rd August',
        'price': 0,
        'exist': false
      },
    ],
  },
  {
    "name": "Skills Evolution",
    "age": "Skills Evolution",
    "discount": 0,
    "min_select": 1,
    "selected": 1,
    "condition": {
      "selected": 5,
      "discount": 0
    },
    "package": [
      {
        'id': 1,
        'name': '12th-19th July',
        'price': 0,
        'exist': true
      },
      {
        'id': 2,
        'name': '19th-26th July',
        'price': 0,
        'exist': false
      },
      {
        'id': 3,
        'name': '2nd-9th August',
        'price': 0,
        'exist': false
      },
      {
        'id': 4,
        'name': '9th-16th August',
        'price': 0,
        'exist': false
      },
      {
        'id': 5,
        'name': '16th-23rd August',
        'price': 0,
        'exist': false
      },
    ],
  },
  {
    "name": "Career Launchpad Programme",
    "age": "Career Launchpad Programme",
    "discount": 0,
    "min_select": 1,
    "selected": 1,
    "condition": {
      "selected": 5,
      "discount": 0
    },
    "package": [
      {
        'id': 1,
        'name': '12th-19th July',
        'price': 0,
        'exist': true
      },
      {
        'id': 2,
        'name': '19th-26th July',
        'price': 0,
        'exist': false
      },
      {
        'id': 3,
        'name': '2nd-9th August',
        'price': 0,
        'exist': false
      },
      {
        'id': 4,
        'name': '9th-16th August',
        'price': 0,
        'exist': false
      },
      {
        'id': 5,
        'name': '16th-23rd August',
        'price': 0,
        'exist': false
      },
    ],
  },

  {
    "name": "IELTS Exam Preparation",
    "age": "IELTS Exam Preparation",
    "discount": 0,
    "min_select": 1,
    "selected": 1,
    "condition": {
      "selected": 5,
      "discount": 0
    },
    "package": [
      {
        'id': 1,
        'name': '12th-19th July',
        'price': 0,
        'exist': true
      },
      {
        'id': 2,
        'name': '19th-26th July',
        'price': 0,
        'exist': false
      },
      {
        'id': 3,
        'name': '2nd-9th August',
        'price': 0,
        'exist': false
      },
      {
        'id': 4,
        'name': '9th-16th August',
        'price': 0,
        'exist': false
      },
      {
        'id': 5,
        'name': '16th-23rd August',
        'price': 0,
        'exist': false
      },
    ],
  },

  {
    "name": "Linguistic & Cultural Odyssey",
    "age": "Linguistic & Cultural Odyssey",
    "discount": 0,
    "min_select": 1,
    "selected": 1,
    "condition": {
      "selected": 5,
      "discount": 0
    },
    "package": [
      {
        'id': 1,
        'name': '12th-19th July',
        'price': 0,
        'exist': true
      },
      {
        'id': 2,
        'name': '19th-26th July',
        'price': 0,
        'exist': false
      },
      {
        'id': 3,
        'name': '2nd-9th August',
        'price': 0,
        'exist': false
      },
      {
        'id': 4,
        'name': '9th-16th August',
        'price': 0,
        'exist': false
      },
      {
        'id': 5,
        'name': '16th-23rd August',
        'price': 0,
        'exist': false
      },
    ],
  },

  {
    "name": "Football Camp",
    "age": "Football Camp",
    "discount": 0,
    "min_select": 1,
    "selected": 1,
    "condition": {
      "selected": 5,
      "discount": 0
    },
    "package": [
      {
        'id': 1,
        'name': '16th-23rd August',
        'price': 0,
        'exist': true
      },
    ],
  },


]