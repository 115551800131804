import React from 'react'
import MainText from '../../text/MainText'
import NormalLightText from '../../text/NormalLightText'
import NormalText from '../../text/NormalText'
import { date, Subject } from '../../../utils/Interfaces';
import { Package, ALevelProgram } from '../../../utils/Interfaces';

interface SummarySectionProps {
  selectedPackage: Package | ALevelProgram | null;
  selectedSubjects: Subject[] | null;
}

const SummarySection: React.FC<SummarySectionProps> = ({ selectedPackage, selectedSubjects }) => {
  return (
    <div>
      <MainText text={"Your Summary"} />
      <div className='w-full mt-2 h-[2px] rounded-full bg-black' />

      <div className='py-4 flex justify-between'>
        <NormalLightText text={"Selected Programme"} />
        <div className='text-right'>
          <NormalText text={selectedPackage !== null ? selectedPackage.name : ""} />
        </div>
      </div>
      <div className='w-full h-[1px] rounded-full bg-textLight' />


      <div className='py-4 flex justify-between'>
        <NormalLightText text={"Selected Weeks"} />
        <div className='text-right'>

          {
            selectedPackage && selectedSubjects ? (
              selectedSubjects.map((subject) => (
                <NormalText key={subject.id} text={subject.name} />
              ))
            ) : (
              <NormalText text={""} />
            )
          }

        </div>
      </div>


      <div className='w-full h-[1px] rounded-full bg-textLight' />
    </div>
  )
}

export default SummarySection